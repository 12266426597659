import React, {Component} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faStar,
  faSuperscript,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";

class Skill extends Component {
  constructor(props) {
    super(props);

    this.buildSkill = this.buildSkill.bind(this);
    this.buildComponent = this.buildComponent.bind(this);
    this.proficientComponents = this.proficientComponents.bind(this);
    this.intermediateComponents = this.intermediateComponents.bind(this);
    this.basicComponents = this.basicComponents.bind(this);
  }

  buildSkill(skill, index) {
    return (
      <div className="col-12" key={`skill_${index}`}>
        <FontAwesomeIcon icon={faAngleRight}/>
          &nbsp; {skill}
      </div>
    );
  }

  buildComponent(level, skills) {
    let indicator;
    if (level === "Proficient") {
      indicator = (
        <div>
          <FontAwesomeIcon icon={faStar}/>
          <FontAwesomeIcon icon={faStar}/>
          <FontAwesomeIcon icon={faStar}/>
        </div>
      );
    } else if (level === "Intermediate") {
      indicator = (
        <div>
          <FontAwesomeIcon icon={faStar}/>
          <FontAwesomeIcon icon={faStar}/>
        </div>
      );
    } else {
      indicator = (
        <div>
          <FontAwesomeIcon icon={faStar}/>
        </div>
      );
    }

    const header = (
      <div>
        {indicator}
        {level}
      </div>
    );
    const skillComponents = skills.map(this.buildSkill);
    const description = (
      <div>
        {skillComponents}
      </div>
    );

    return [header, description];
  }

  proficientComponents() {
    return this.buildComponent("Proficient", this.props.data.proficient);
  }

  intermediateComponents() {
    return this.buildComponent("Intermediate", this.props.data.intermediate);
  }

  basicComponents() {
    return this.buildComponent("Basic", this.props.data.basic);
  }

  render() {
    const skillHeader = (
      <h4 className="col-12">
        <FontAwesomeIcon icon={faSuperscript}/>
        &nbsp; Skill
      </h4>
    );
    const proficient = this.proficientComponents();
    const intermediate = this.intermediateComponents();
    const basic = this.basicComponents();

    return (
      <div className="grid">
        {skillHeader}
        <div className="col-4">
          {this.props.builder(basic)}

        </div>
        <div className="col-4">
          {this.props.builder(intermediate)}
        </div>
        <div className="col-4">
          {this.props.builder(proficient)}
        </div>
      </div>
    );
  }
}

Skill.propTypes = {
  data: PropTypes.shape({
    proficient: PropTypes.arrayOf(PropTypes.string).isRequired,
    intermediate: PropTypes.arrayOf(PropTypes.string).isRequired,
    basic: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  builder: PropTypes.func.isRequired
};

export default Skill;
