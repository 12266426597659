import React, {Component} from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import {Outlet, useLocation} from "react-router";

/*
  Google Analytics pageview logging.
*/
class Logger extends Component {
  /*
    Constructs the Logger Component.
  */
  constructor(props) {
    super(props);
    ReactGA.initialize("UA-81148803-1");
  }

  /*
    Returns the null component. Set the page user is on.
  */
  render() {
    ReactGA.set({page: this.props.location.pathname});
    ReactGA.pageview(this.props.location.pathname);
    return null;
  }
}

/*
  Define propTypes.
*/
Logger.propTypes = {
  location: PropTypes.object
};

const LoggerWithLocation = () => {
  const location = useLocation();
  return (
    <React.Fragment>
      <Logger location={location}/>
      <Outlet/>
    </React.Fragment>
  );
};

export default LoggerWithLocation;
