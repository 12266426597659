import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

class PageNotFound extends Component {
  render() {
    return (
      <div className="grid">
        <div className="col-12">
          <div className="card">
            <h1>
              <FontAwesomeIcon icon={faExclamationCircle}/>
              &nbsp; Page Not Found
            </h1>
            <p>The page you are looking for is not available.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default PageNotFound;
