import {initializeApp} from "firebase/app";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  startAfter,
  query
} from "firebase/firestore";

export const firebase = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
});

export default class FirestoreService {
  constructor() {
    this.db = getFirestore();
  }

  /**
   * Field to fetch from me collection.
   *
   * @param {string} field
   * @return {Promise<DocumentSnapshot<DocumentData>>}
   */
  getResume(field) {
    return getDoc(doc(this.db, "me", field));
  }

  /**
   * Fetches the project up the n at a time. If after is specified, it will fetch projects up to n starting
   * after the specified project.
   *
   * @param {int} n
   * @param {QueryDocumentSnapshot} after
   * @return {Promise<DocumentSnapshot<DocumentData>>}
   */
  getProjects(n, after) {
    const projectsRef = collection(this.db, "projects");
    const constraints = [orderBy("id", "desc"), limit(n)];
    if (after) {
      constraints.push(startAfter(after));
    }
    const q = query(projectsRef, ...constraints);
    return getDocs(q);
  }
}
