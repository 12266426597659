import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AppMenuItem from "./AppMenuItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import {
  faAlignLeft,
  faAngleDown,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

class AppTopbar extends Component {
  constructor(props) {
    super(props);

    this.menuItems = [
      {
        as: "a",
        icon: faGithub,
        text: "Github",
        to: "https://www.github.com/lamdav"
      },
      {
        as: "a",
        icon: faLinkedin,
        text: "LinkedIn",
        to: "https://www.linkedin.com/in/lamdav/"
      },
      {
        as: "link",
        icon: faEnvelope,
        text: "Contact",
        to: "/mail"
      }
    ];
  }

  onTopbarItemClick(event, item) {
    if (this.props.onTopbarItemClick) {
      this.props.onTopbarItemClick({
        originalEvent: event,
        item: item
      });
    }
  }

  createMenuItem(item, index) {
    return (
      <AppMenuItem
        key={`appMenuItem${index}`}
        as={item.as}
        icon={item.icon}
        text={item.text}
        to={item.to}
      />
    );
  }

  render() {
    const topbarItemsClassName = classNames("topbar-menu fadeInDown", {"topbar-menu-visible": this.props.topbarMenuActive});

    return (
      <div className="topbar clearfix">
        <Link className="logo-link" to="/home">
          <img className="logo" alt="apollo-layout" src="/logo.svg"/>
        </Link>

        {/* Not shown in slim mode */}
        <button
          type="button"
          className="p-link menu-button"
          id="menu-button"
          onClick={this.props.onMenuButtonClick}
        >
          <FontAwesomeIcon icon={faAlignLeft}/>
        </button>

        {/* Avatar to top right */}
        <button
          type="button"
          className="p-link profile"
          onClick={this.props.onTopbarMenuButtonClick}
        >
          <span className="username">David Lam</span>
          <img src="/pic/avatar.svg" alt="apollo-layout"/>
          <FontAwesomeIcon icon={faAngleDown}/>
        </button>

        {/* Menu Items */}
        <ul className={topbarItemsClassName}>
          {this.menuItems.map(this.createMenuItem)}
        </ul>
      </div>
    );
  }
}

AppTopbar.defaultProps = {
  onMenuButtonClick: null,
  onTopbarItemClick: null,
  onTopbarMenuButtonClick: null,
  topbarMenuActive: false
};

AppTopbar.propTypes = {
  onMenuButtonClick: PropTypes.func.isRequired,
  onTopbarItemClick: PropTypes.func.isRequired,
  onTopbarMenuButtonClick: PropTypes.func.isRequired,
  topbarMenuActive: PropTypes.bool.isRequired
};

export default AppTopbar;
