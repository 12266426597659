import React, {Component} from "react";

import DetailPanel from "./DetailPanel.jsx";

class HomePage extends Component {
  render() {
    return (
      <div className="grid">
        <DetailPanel/>
      </div>
    );
  }
}

export default HomePage;
