import React, {Component} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faAt,
  faMapMarkedAlt
} from "@fortawesome/free-solid-svg-icons";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.addressComponents = this.addressComponents.bind(this);
    this.emailComponents = this.emailComponents.bind(this);
  }

  addressComponents() {
    const addressHeader = (
      <div>
        <FontAwesomeIcon icon={faMapMarkedAlt}/>
        &nbsp; Address
      </div>
    );
    const cityStateZip = `${this.props.data.address.city}, ${this.props.data.address.state} ${this.props.data.address.zip}`;
    const addressDescription = (
      <div>
        {this.props.data.name}
        <br/> {this.props.data.address.place}
        <br/> {cityStateZip}
      </div>
    );

    return [addressHeader, addressDescription];
  }

  emailComponents() {
    const emailHeader = (
      <div>
        <FontAwesomeIcon icon={faAt}/>
        &nbsp; Email
      </div>
    );
    const mailto = `mailto:${this.props.data.email}?Subject=Hello%20David`;
    const emailDescription = (
      <div>
        <a href={mailto} target="_top">
          {this.props.data.email}
        </a>
      </div>
    );

    return [emailHeader, emailDescription];
  }

  render() {
    const contactHeader = (
      <h4 className="col-12">
        <FontAwesomeIcon icon={faAddressCard}/>
        &nbsp; Contact
      </h4>
    );
    const address = this.addressComponents();
    const email = this.emailComponents();

    return (
      <div className="grid">
        {contactHeader}
        <div className="col-6">
          {this.props.builder(address)}
        </div>
        <div className="col-6">
          {this.props.builder(email)}
        </div>
      </div>
    );
  }
}

Contact.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    address: PropTypes.shape({
      place: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  builder: PropTypes.func.isRequired
};

export default Contact;
