import React, {Component} from "react";
import PropTypes from "prop-types";
import {Card} from "primereact/card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRunning} from "@fortawesome/free-solid-svg-icons";

class Activity extends Component {
  constructor(props) {
    super(props);
    this.buildActivityItem = this.buildActivityItem.bind(this);
  }

  buildActivityItem(activity, index) {
    const statusRange = `${activity.status} ${activity.dates}`;
    const statusRangeStyle = {
      textAlign: "right"
    };

    return (
      <React.Fragment key={`activity_${index}`}>
        <div className="col-6">
          {activity.title}
        </div>
        <div className="col-6" style={statusRangeStyle}>
          {statusRange}
        </div>
      </React.Fragment>
    );
  }

  render() {
    const activityHeader = (
      <h4>
        <FontAwesomeIcon icon={faRunning}/>
        &nbsp; Activity
      </h4>
    );
    const activities = this.props.activity.map(this.buildActivityItem);
    return (
      <div className="grid">
        <div className="col-12">
          {activityHeader}
        </div>
        <div className="col-12">
          <Card style={{boxShadow: "0 2px 1px -1px rgb(73 197 177), 0 1px 1px 0 rgb(0 0 0), 0 1px 3px 0 rgb(117 147 142)"}}>
            <div className="grid">
              {activities}
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

Activity.propTypes = {
  activity: PropTypes.arrayOf(PropTypes.shape({title: PropTypes.string.isRequired, status: PropTypes.string.isRequired, dates: PropTypes.string.isRequired}).isRequired)
};

export default Activity;
