import React, {Component} from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

import BackendService from "../../services/BackendService.jsx";

class BlogView extends Component {
  constructor(props) {
    super(props);

    this.service = new BackendService();

    this.state = {
      article: null,
      notFound: false
    };

    this.fetchContent = this.fetchContent.bind(this);
  }

  fetchContent() {
    this.service.getBlogContent(this.props.title)
      .then((content) => this.setState({article: content, notFound: false}))
      .catch((err) => this.setState({article: null, notFound: true}));
  }

  componentDidUpdate(prevProps) {
    if (this.props.title !== prevProps.title) {
      this.fetchContent();
    }
  }

  componentDidMount() {
    if (this.props.title) {
      this.fetchContent();
    }
  }

  render() {
    let articleComponent = (
      <ReactMarkdown>
        {this.state.article}
      </ReactMarkdown>
    );
    if (this.state.notFound) {
      articleComponent = (
        <h1>
          <FontAwesomeIcon icon={faExclamationCircle}/>
          &nbsp; Article Not Found
        </h1>
      );
    }

    return (
      <div>
        <div className="grid">
          <div className="col-12">
            <div className="card">
              {articleComponent}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BlogView.propTypes = {
  title: PropTypes.string.isRequired
};

export default BlogView;
