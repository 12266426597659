import React, {Component} from "react";
import PropTypes from "prop-types";
import {Card} from "primereact/card";

import Role from "./Role.jsx";

class Work extends Component {
  constructor(props) {
    super(props);
    this.buildRole = this.buildRole.bind(this);
  }

  buildRole(role, index) {
    return (<Role key={this.props.work.company + role.title + index} role={role}/>);
  }

  render() {
    const companyLocation = `${this.props.work.company}, ${this.props.work.location}`;
    const roles = this.props.work.roles.map(this.buildRole);
    return (
      <div className="col-12">
        <Card style={{boxShadow: "0 2px 1px -1px rgb(73 197 177), 0 1px 1px 0 rgb(0 0 0), 0 1px 3px 0 rgb(117 147 142)"}}>
          <div className="grid">
            <div className="col-12" style={{fontWeight: "bold"}}>
              {companyLocation}
            </div>

            <div className="col-12">
              {roles}
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

Work.propTypes = {
  work: PropTypes.shape({
    company: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      dates: PropTypes.arrayOf(PropTypes.string).isRequired,
      detail: PropTypes.arrayOf(PropTypes.string).isRequired
    })).isRequired
  }).isRequired
};

export default Work;
