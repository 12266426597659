import React, {Component} from "react";

import LifeManager from "./LifeManager.jsx";

class GameOfLifePage extends Component {
  render() {
    return (
      <div>
        <h1>
          Game of Life
        </h1>
        <div className="animated fadeIn">
          <LifeManager width={57} height={28}/>
        </div>
      </div>
    );
  }
}

export default GameOfLifePage;
