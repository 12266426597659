import React, {Component} from "react";
import VisibilitySensor from "react-visibility-sensor";
import {ProgressBar} from "primereact/progressbar";
import {ProgressSpinner} from "primereact/progressspinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

import Project from "./Project.jsx";
import FirestoreService from "../../firebase";

class ProjectPage extends Component {
  constructor(props) {
    super(props);

    this.db = new FirestoreService();
    this.state = {
      projects: [],
      lastProject: null,
      loadMore: true,
      loadError: null
    };

    this.createProject = this.createProject.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
  }

  createProject(projectSnapshot, index) {
    const data = projectSnapshot.data();
    return (
      <div className="col-12" key={`${data.title}_${index}`}>
        <Project
          title={data.title}
          description={data.description}
          links={data.links}
        />
      </div>
    );
  }

  componentDidMount() {
    try {
      this.db.getProjects(10)
        .then((documents) => this.setState({projects: documents.docs, lastProject: documents.docs[documents.size - 1]}));
    } catch (e) {
      console.error(e);
      this.setState({loadMore: false, loadError: e});
    }
  }

  handleLoad(isVisible) {
    const lastProject = this.state.lastProject;
    if (isVisible && lastProject && this.state.loadMore) {
      try {
        this.db.getProjects(10, lastProject)
          .then((documents) => {
            if (documents.empty) {
              this.setState({loadMore: false});
            } else {
              this.setState((prevState, props) => {
                return {
                  projects: prevState.projects.concat(documents.docs),
                  lastProject: documents.docs[documents.size - 1],
                  loadMore: true
                };
              });
            }
          });
      } catch (e) {
        console.error(e);
        this.setState({loadMore: false, loadError: e});
      }
    }
  }

  render() {
    const loadFailureComponent = (
      <div key="load-failure" className="col-12 card">
        <h1>
          <FontAwesomeIcon icon={faExclamationCircle}/>
          &nbsp; Uh-oh failed to fetch from Database.
        </h1>
      </div>
    );

    const projectComponents = this.state.projects.map(this.createProject);
    if (this.state.loadMore && this.state.loadError === null) {
      projectComponents.push(
        <VisibilitySensor key="visibility-sensor-progress-bar" onChange={this.handleLoad}>
          <div className="col-12">
            <ProgressBar mode="indeterminate"/>
          </div>
        </VisibilitySensor>
      );
    } else if (this.state.loadError) {
      projectComponents.push(loadFailureComponent);
    }

    let component = projectComponents;
    if (projectComponents.length === 0) {
      if (this.state.loadError) {
        component = loadFailureComponent;
      } else {
        component = (
          <div className="col-12 card">
            <h1>
              <ProgressSpinner style={{height: "1em", width: "1em"}}/>
              &nbsp; Wait just one second
            </h1>
            Fetching content from Database.
          </div>
        );
      }
    }

    return (
      <div className="grid">
        {component}
      </div>
    );
  }
}

export default ProjectPage;
