import React, {Component} from "react";
import PropTypes from "prop-types";

const LENGTH = 20;

class Cell extends Component {
  render() {
    let fillColor = "gray";
    if (this.props.status) {
      fillColor = "black";
    }

    return (
      <rect
        width={LENGTH}
        height={LENGTH}
        x={LENGTH * this.props.row}
        y={LENGTH * this.props.col}
        fill={fillColor}
        stroke="cyan"
        strokeWidth="1"
      />
    );
  }
}

Cell.propTypes = {
  status: PropTypes.bool.isRequired,
  row: PropTypes.number.isRequired,
  col: PropTypes.number.isRequired
};

export default Cell;
