import React, {Component} from "react";
import PropTypes from "prop-types";
import {Card} from "primereact/card";

class Content extends Component {
  render() {
    const style = {
      height: "100%",
      padding: "10px",
      boxShadow: "0 2px 1px -1px rgb(73 197 177), 0 1px 1px 0 rgb(0 0 0), 0 1px 3px 0 rgb(117 147 142)"
    };
    return (
      <Card header={this.props.header} style={style}>
        <div className="grid">
          {this.props.description}
        </div>
      </Card>
    );
  }
}

Content.propTypes = {
  header: PropTypes.element.isRequired,
  description: PropTypes.element.isRequired
};

export default Content;
