import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

class AppMenuItem extends Component {
  render() {
    let link = null;
    const className = "topbar-icon";
    if (this.props.as === "link") {
      link = (
        <Link to={this.props.to}>
          <button type="button" className="p-link">
            <FontAwesomeIcon className={className} icon={this.props.icon}/>
            <span>{this.props.text}</span>
          </button>
        </Link>
      );
    } else if (this.props.as === "a") {
      link = (
        <a href={this.props.to} target="_blank" rel="noopener noreferrer">
          <button type="button" className="p-link">
            <FontAwesomeIcon className={className} icon={this.props.icon}/>
            <span>{this.props.text}</span>
          </button>
        </a>
      );
    }

    return (
      <li role="menuitem">
        {link}
      </li>
    );
  }
}

AppMenuItem.propTypes = {
  as: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default AppMenuItem;
