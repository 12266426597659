import React, {Component} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";

class Role extends Component {
  constructor(props) {
    super(props);

    this.buildDetail = this.buildDetail.bind(this);
  }

  buildDetail(detail, index) {
    return (
      <div className="col-12" key={`detail_${index}`} style={{padding: "10px"}}>
        <FontAwesomeIcon icon={faAngleRight}/>
        &nbsp; {detail}
      </div>
    );
  }

  render() {
    const dateRange = this.props.role.dates.join(" | ");
    const details = this.props.role.detail.map(this.buildDetail);
    const dateRangeStyle = {
      textAlign: "right"
    };
    const nestedCardStyle = {
      fontWeight: "bold"
    };

    return (
      <React.Fragment>
        <div className="grid">
          <div className="col-6" style={nestedCardStyle}>
            {this.props.role.title}
          </div>
          <div className="col-6" style={dateRangeStyle}>
            {dateRange}
          </div>

          {details}
        </div>
      </React.Fragment>
    );
  }
}

Role.propTypes = {
  role: PropTypes.shape({
    title: PropTypes.string.isRequired,
    dates: PropTypes.arrayOf(PropTypes.string).isRequired,
    detail: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired
};

export default Role;
