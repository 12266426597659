import React, {Component} from "react";
import {
  useLocation
} from "react-router";
import {NavLink} from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import {Ripple} from "primereact/ripple";
import {Tooltip} from "primereact/tooltip";
import {CSSTransition} from "react-transition-group";

class AppSubmenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: null
    };

    this.createItems = this.createItems.bind(this);
  }

  onMenuItemClick(event, item, index) {
    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    if (this.props.root && this.props.onRootItemClick) {
      this.props.onRootItemClick({
        originalEvent: event,
        item: item
      });
    }

    // execute command
    if (item.command) {
      item.command({originalEvent: event, item: item});
      event.preventDefault();
    }

    if (this.state.activeIndex !== index) {
      this.setState({activeIndex: index});
    }

    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick({
        originalEvent: event,
        item: item
      });
    }
  }

  onKeyDown(event, item, index) {
    if (event.key === "Enter") {
      this.onMenuItemClick(event, item, index);
    }
  }

  onMenuItemMouseEnter(index) {
    //     if (this.props.root && this.props.menuActive && this.isHorizontalOrSlim()) {
    //       this.setState({activeIndex: index});
    //     }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.isHorizontalOrSlim() && prevProps.menuActive && !this.props.menuActive) {
      this.setState({activeIndex: null});
    }
  }

  componentDidMount() {
    const location = this.props.location;
    if (location.pathname === "/") {
      this.setState({activeIndex: 0});
    } else {
      const items = this.props.items;
      if (items) {
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          if (location.pathname.startsWith(item.to)) {
            this.setState({activeIndex: i});
            break;
          }
        }
      }
    }
  }

  isHorizontalOrSlim() {
    return (this.props.layoutMode === "horizontal" || this.props.layoutMode === "slim");
  }

  renderLinkContent(item) {
    let submenuIcon = null;
    if (item.items) {
      submenuIcon = (
        <FontAwesomeIcon className="layout-menuitem-toggler" icon={faAngleDown}/>
      );
    }

    let badge = null;
    if (item.badge) {
      badge = <span className="menuitem-badge">{item.badge}</span>;
    }

    return (
      <React.Fragment>
        <FontAwesomeIcon icon={item.icon}/>
        <span>{item.label}</span>
        {submenuIcon}
        {badge}
      </React.Fragment>
    );
  }

  renderLink(item, index) {
    const content = this.renderLinkContent(item);

    const styleClass = classNames(item.class, "p-ripple", `${item.label.split(" ").join("-")}-tooltip-ref`);

    if (item.to) {
      return (
        <NavLink
          activeclassname="router-link-active"
          to={item.to}
          onClick={(e) => this.onMenuItemClick(e, item, index)}
          role="menuitem"
          target={item.target}
          onMouseEnter={(e) => this.onMenuItemMouseEnter(index)}
          className={styleClass}
          data-pr-tooltip={item.label}
          data-pr-position="right"
        >
          {content}
          <Ripple/>
        </NavLink>
      );
    }
    return (
      <a
        href={item.url}
        tabIndex={item.url ? "" : 0}
        role="menuitem"
        onClick={(e) => this.onMenuItemClick(e, item, index)}
        target={item.target}
        onMouseEnter={(e) => this.onMenuItemMouseEnter(index)}
        onKeyDown={(e) => this.onKeyDown(e, item, index)}
        className={styleClass}
      >
        {content}
        <Ripple/>
      </a>
    );
  }

  createItems(item, index) {
    const active = this.state.activeIndex === index;
    const styleClass = classNames(
      item.badgeStyleClass,
      {"active-menuitem": active},
      {"layout-root-menuitem": this.props.root}
    );
    const mobileLinkComponent = (
      <NavLink
        className="layout-menuitem-root-text"
        activeclassname="router-link-active"
        style={{textTransform: "uppercase"}}
        to={item.to}
        onClick={(e) => this.onMenuItemClick(e, item, index)}
        role="menuitem"
        target={item.target}
        onMouseEnter={(e) => this.onMenuItemMouseEnter(index)}
      >
        <span>{item.label}</span>
      </NavLink>
    );
    const mobileLink = this.props.root ? mobileLinkComponent : null;

    return (
      <li
        className={styleClass}
        key={index}
        role="none"
      >
        {(item.items && this.props.root) ? <div className="arrow"/> : null}
        {this.renderLink(item, index)}
        {mobileLink}
        <Tooltip target={`.${item.label.split(" ").join("-")}-tooltip-ref`}/>
        <CSSTransition
          classNames="layout-submenu-container"
          timeout={{enter: 400, exit: 400}}
          in={item.items && (this.props.root && !((this.isHorizontal() || this.isSlim()) && !this.isMobile() && (!this.isSlim() || (this.isSlim() && this.state.activeIndex !== null))) ? true : active)}
          unmountOnExit
        >
          <AppSubmenu
            items={item.items}
            onMenuItemClick={this.props.onMenuItemClick}
            layoutMode={this.props.layoutMode}
            menuActive={this.props.menuActive}
            parentMenuItemActive={active}
            location={this.props.location}
          />
        </CSSTransition>
      </li>
    );
  }

  render() {
    let items = null;
    if (this.props.items) {
      items = this.props.items.map(this.createItems);
    }

    let component = null;
    if (items) {
      component = (
        <ul
          role="menu"
          className={this.props.className}
        >
          {items}
        </ul>
      );
    }

    return component;
  }
}

AppSubmenu.defaultProps = {
  className: null,
  items: null,
  layoutMode: null,
  menuActive: false,
  onMenuItemClick: null,
  onRootItemClick: null,
  parentMenuItemActive: false,
  root: false
};

AppSubmenu.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  layoutMode: PropTypes.string,
  menuActive: PropTypes.bool,
  onMenuItemClick: PropTypes.func,
  onRootItemClick: PropTypes.func,
  parentMenuItemActive: PropTypes.bool,
  root: PropTypes.bool,
  location: PropTypes.object.isRequired
};

class AppMenu extends Component {
  render() {
    return (
      <AppSubmenu
        items={this.props.model}
        className="layout-menu layout-main-menu clearfix"
        menuActive={this.props.active}
        onRootItemClick={this.props.onRootMenuItemClick}
        onMenuItemClick={this.props.onMenuItemClick}
        root={true} layoutMode={this.props.layoutMode}
        parentMenuItemActive={true}
        location={this.props.location}
      />
    );
  }
}

AppMenu.defaultProps = {
  active: false,
  layoutMode: null,
  model: null,
  onMenuItemClick: null,
  onRootMenuItemClick: null
};

AppMenu.propTypes = {
  active: PropTypes.bool,
  layoutMode: PropTypes.string,
  model: PropTypes.array,
  onMenuItemClick: PropTypes.func,
  onRootMenuItemClick: PropTypes.func,
  location: PropTypes.object.isRequired
};

const AppMenuWithLocation = (props) => {
  const location = useLocation();
  return (
    <AppMenu
      active={props.active}
      layoutMode={props.layoutMode}
      model={props.model}
      onMenuItemClick={props.onMenuItemClick}
      onRootMenuItemClick={props.onRootMenuItemClick}
      location={location}
    />
  );
};

AppMenuWithLocation.propTypes = {
  active: PropTypes.bool,
  layoutMode: PropTypes.string,
  model: PropTypes.array,
  onMenuItemClick: PropTypes.func,
  onRootMenuItemClick: PropTypes.func
};

export default AppMenuWithLocation;
