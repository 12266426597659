import React, {Component} from "react";

import Resume from "./Resume.jsx";

class ResumePage extends Component {
  render() {
    return (
      <div>
        <div className="grid">
          <div className="col-12">
            <h1>Resume</h1>
          </div>
          <div className="col-12">
            <Resume/>
          </div>
        </div>
      </div>
    );
  }
}

export default ResumePage;
