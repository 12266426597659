import React, {Component} from "react";
import PropTypes from "prop-types";
import {Fieldset} from "primereact/fieldset";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faGitlab,
  faNpm,
  faPython,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";

class Project extends Component {
  constructor(props) {
    super(props);

    this.createLinkButton = this.createLinkButton.bind(this);
  }

  /*
    Create all links.
  */
  createLinkButton(link, index) {
    let linkButton;
    const linkButtonClassName = "p-button";
    if (link.indexOf("youtube") > -1) {
      linkButton = (
        <button className={linkButtonClassName} style={{backgroundColor: "red", padding: "0.5em"}}>
          <FontAwesomeIcon icon={faYoutube}/>
          <span>&nbsp; View on Youtube</span>
        </button>
      );
    } else if (link.indexOf("npmjs") > -1) {
      linkButton = (
        <button className={linkButtonClassName} style={{backgroundColor: "red", padding: "0.5em"}}>
          <FontAwesomeIcon icon={faNpm}/>
          <span>&nbsp; View on NPM</span>
        </button>
      );
    } else if (link.indexOf("github") > -1) {
      linkButton = (
        <button className={linkButtonClassName} style={{backgroundColor: "grey", padding: "0.5em"}}>
          <FontAwesomeIcon icon={faGithub}/>
          <span>&nbsp; View on Github</span>
        </button>
      );
    } else if (link.indexOf("ada") > -1 || link.indexOf("gitlab") > -1) {
      linkButton = (
        <button className={linkButtonClassName} style={{backgroundColor: "orange", padding: "0.5em"}}>
          <FontAwesomeIcon icon={faGitlab}/>
          <span>&nbsp; View on Gitlab</span>
        </button>
      );
    } else if (link.indexOf("pypi") > -1) {
      linkButton = (
        <button className={linkButtonClassName} style={{backgroundColor: "#CCAA00", padding: "0.5em"}}>
          <FontAwesomeIcon icon={faPython}/>
          <span>&nbsp; View on PyPi</span>
        </button>
      );
    } else {
      linkButton = (
        <button className={`${linkButtonClassName} p-button-info`} style={{padding: "0.5em"}}>
          <FontAwesomeIcon icon={faExternalLinkAlt}/>
          <span>&nbsp; View Demo</span>
        </button>
      );
    }

    return (
      <a href={link} target="_blank" rel="noopener noreferrer" role="button" key={link + index}>
        {linkButton}
        &nbsp;&nbsp;
      </a>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Fieldset legend={this.props.title}>
          <div className="grid">
            <div className="col-12">
              <p>
                {this.props.description}
              </p>
            </div>
          </div>

          <div className="grid">
            <div className="col-12">
              {this.props.links.map(this.createLinkButton)}
            </div>
          </div>
        </Fieldset>
      </React.Fragment>
    );
  }
}

Project.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.string)
};

export default Project;
