import React, {Component} from "react";
import PropTypes from "prop-types";
import {Dropdown} from "primereact/dropdown";

import BackendService from "../../services/BackendService.jsx";
import BlogView from "./BlogView.jsx";
import {
  useNavigate,
  useParams
} from "react-router";

class BlogPage extends Component {
  constructor(props) {
    super(props);

    this.service = new BackendService();

    this.state = {
      posts: [],
      title: null,
      placeholder: "loading"
    };

    this.constructDropdownItem = this.constructDropdownItem.bind(this);
    this.onDropdownChange = this.onDropdownChange.bind(this);
  }

  constructDropdownItem(post, index) {
    return {
      label: post,
      value: post
    };
  }

  onDropdownChange(event) {
    this.setState({title: event.value});
    this.props.navigate(`/blog/${event.value}`);
  }

  componentDidMount() {
    this.service.getBlogPost()
      .then((posts) => posts.map(this.constructDropdownItem))
      .then((items) => {
        let title = this.props.match.title;
        if (items.length > 0 && !title) {
          title = items[0].value;
        }
        this.setState({posts: items, placeholder: title, title});
        this.props.navigate(`/blog/${title}`);
      })
      .catch((err) => this.setState({posts: [], placeholder: `failed to fetch posts: ${err.message}`}));
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.title && this.props.match.title !== prevProps.match.title) {
      this.setState({title: this.props.match.title});
    }
  }

  render() {
    return (
      <div>
        <div className="grid">
          <div className="col-3">
            <h1>Blog</h1>
          </div>
          <div className="col-9">
            <Dropdown
              disabled={this.state.posts.length === 0}
              options={this.state.posts}
              onChange={this.onDropdownChange}
              placeholder={this.state.placeholder}
              style={{width: "100%"}}
              value={this.state.title}
            />
          </div>

          <div className="col-12">
            {(this.state.title)
              ? <BlogView title={this.state.title}/>
              : null}
          </div>
        </div>
      </div>
    );
  }
}

BlogPage.propTypes = {
  navigate: PropTypes.func.isRequired,
  match: PropTypes.shape({
    title: PropTypes.string
  }).isRequired
};

const BlogPageWithRoutes = () => {
  const match = useParams();
  const navigate = useNavigate();
  return (
    <BlogPage navigate={navigate} match={match}/>
  );
};

export default BlogPageWithRoutes;
