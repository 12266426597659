import React from "react";
import {createRoot} from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import {config} from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.min.css";

import App from "./App.jsx";
import "./assets/theme/theme-blue-dark.css";
import "./assets/layout/css/layout-blue-dark.css";

config.autoAddCss = false;

const container = document.getElementById("base");
const root = createRoot(container);
root.render(<App/>);
serviceWorker.register();
