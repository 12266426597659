import React, {Component} from "react";
import PropTypes from "prop-types";
import {useLocation} from "react-router";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";

import ResumeDownloader from "../components/resume/ResumeDownloader.jsx";

class AppBreadcrumb extends Component {
  render() {
    const location = this.props.location;
    const paths = location.pathname.split("/")
      .filter((path) => path !== "")
      .reduce((accum, path, index, orig) => {
        accum.push(<li key={`breadcumb_${index}`}>{path}</li>);
        if (index !== orig.length - 1) {
          accum.push(<li key={`separator_suffix_${index}`}>/</li>);
        }
        return accum;
      }, [<li key="separator_prefix">/</li>]);

    return (
      <div className="layout-breadcrumb">
        <ul>
          <li>
            <Link to="/home">
              <button className="p-link">
                <FontAwesomeIcon icon={faHome}/>
              </button>
            </Link>
          </li>
          {paths}
        </ul>

        <div className="layout-breadcrumb-options">
          <ResumeDownloader/>
        </div>
      </div>
    );
  }
}

AppBreadcrumb.propTypes = {
  location: PropTypes.object.isRequired
};

const AppBreadcrumbWithLocation = () => {
  const location = useLocation();
  return (
    <AppBreadcrumb location={location}/>
  );
};

export default AppBreadcrumbWithLocation;
