import React, {Component} from "react";
import {ProgressBar} from "primereact/progressbar";

import Activity from "./Activity.jsx";
import Contact from "./Contact.jsx";
import Content from "./Content.jsx";
import Education from "./Education.jsx";
import Experience from "./Experience.jsx";
import FirestoreService from "../../firebase";
import Skill from "./Skill.jsx";

class Resume extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: null,
      objective: null,
      education: null,
      skill: null,
      experience: null,
      activity: null
    };

    this.db = new FirestoreService();
    this.buildContent = this.buildContent.bind(this);
  }

  componentDidMount() {
    try {
      this.db.getResume("contact")
        .then((snapshot) => snapshot.exists() && this.setState({contact: snapshot.data()}));

      this.db.getResume("education")
        .then((snapshot) => snapshot.exists() && this.setState({education: snapshot.data()}));

      this.db.getResume("fields")
        .then((snapshot) => {
          if (!snapshot.exists()) {
            return;
          }
          const data = snapshot.data();
          const objective = data.objective;
          const experience = data.experience;
          const activity = data.activity;
          this.setState({objective, experience, activity});
        });

      this.db.getResume("skill")
        .then((snapshot) => snapshot.exists() && this.setState({skill: snapshot.data()}));
    } catch (e) {
      console.error(`failed to fetch resume details from firebase: ${e}`);
    }
  }

  buildContent(component, index) {
    const header = component[0];
    const description = component[1];
    return (<Content key={index} header={header} description={description}/>);
  }

  render() {
    const loading = (
      <ProgressBar mode="indeterminate"/>
    );

    return (
      <div>
        <div className="grid">
          <div className="col-12">
            {this.state.contact
              ? <Contact data={this.state.contact} builder={this.buildContent}/>
              : loading}
          </div>
          <div className="col-12">
            {this.state.education
              ? <Education data={this.state.education} builder={this.buildContent}/>
              : loading}
          </div>
          <div className="col-12">
            {this.state.experience
              ? <Experience data={this.state.experience}/>
              : loading}
          </div>
          <div className="col-12">
            {this.state.skill
              ? <Skill data={this.state.skill} builder={this.buildContent}/>
              : loading}
          </div>
          <div className="col-12">
            {this.state.activity
              ? <Activity activity={this.state.activity}/>
              : loading}
          </div>
        </div>
      </div>
    );
  }
}

export default Resume;
