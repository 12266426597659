import React, {Component} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKeyboard} from "@fortawesome/free-solid-svg-icons";

import Work from "./Work.jsx";

class Experience extends Component {
  constructor(props) {
    super(props);
    this.buildWork = this.buildWork.bind(this);
  }

  buildWork(work, index) {
    return (
      <div className="col-12" key={work.company + index}>
        <Work work={work}/>
      </div>
    );
  }

  render() {
    const experienceHeader = (
      <h4 className="col-12">
        <FontAwesomeIcon icon={faKeyboard}/>
        &nbsp; Experience
      </h4>
    );
    const workComponents = this.props.data.map(this.buildWork);
    return (
      <div>
        <div className="grid">
          {experienceHeader}
          {workComponents}
        </div>
      </div>
    );
  }
}

Experience.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    company: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      dates: PropTypes.arrayOf(PropTypes.string).isRequired,
      detail: PropTypes.arrayOf(PropTypes.string).isRequired
    })).isRequired
  })).isRequired
};

export default Experience;
