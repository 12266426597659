import React, {Component} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faChalkboardTeacher,
  faScroll,
  faUserGraduate
} from "@fortawesome/free-solid-svg-icons";

class Education extends Component {
  constructor(props) {
    super(props);

    this.schoolComponents = this.schoolComponents.bind(this);
    this.specializedCourses = this.specializedCoursesComponents.bind(this);
  }

  schoolComponents() {
    const schoolHeader = (
      <div>
        <FontAwesomeIcon icon={faScroll}/>
        &nbsp; School
      </div>
    );


    const majors = this.props.data.majors.join(" and ");

    const schoolDescription = (
      <div>
        <i>{this.props.data.school}</i>
        <br/>
        {this.props.data.degreeType} in {majors}
        <br/>
        {this.props.data.graduationDate} with {this.props.data.gpa} GPA
      </div>
    );

    return [schoolHeader, schoolDescription];
  }

  specializedCoursesComponents() {
    const courseHeader = (
      <div>
        <FontAwesomeIcon icon={faChalkboardTeacher}/>
        &nbsp; Specialized Courses
      </div>
    );
    const courses = this.props.data.courses.map((course, index) => {
      return (
        <div key={`course_${index}`}>
          <span> {course} </span>
          <br/>
        </div>
      );
    });
    const courseDescription = (
      <div>
        {courses}
      </div>
    );

    return [courseHeader, courseDescription];
  }

  render() {
    const educationHeader = (
      <h4 className="col-12">
        <FontAwesomeIcon icon={faUserGraduate}/>
        &nbsp; Education
      </h4>
    );
    const school = this.schoolComponents();
    const specializedCourses = this.specializedCoursesComponents();

    return (
      <div className="grid">
        {educationHeader}
        <div className="col-6">
          {this.props.builder(school)}
        </div>

        <div className="col-6">
          {this.props.builder(specializedCourses)}
        </div>
      </div>
    );
  }
}

Education.propTypes = {
  data: PropTypes.shape({
    degreeType: PropTypes.string.isRequired,
    majors: PropTypes.arrayOf(PropTypes.string).isRequired,
    minors: PropTypes.arrayOf(PropTypes.string),
    graduationDate: PropTypes.string.isRequired,
    school: PropTypes.string.isRequired,
    gpa: PropTypes.string.isRequired,
    courses: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  builder: PropTypes.func.isRequired
};

export default Education;
