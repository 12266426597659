import React, {Component} from "react";
import isEmail from "validator/lib/isEmail";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {Toast} from "primereact/toast";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";

import BackendService from "../../services/BackendService.jsx";

class MailForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      emailValid: false,
      subject: "",
      subjectValid: false,
      message: "",
      messageValid: false,
      loading: false,
      success: false,
      failure: false,
      failureMessages: []
    };

    this.service = new BackendService();
    this.toast = null;

    this.showMessages = this.showMessages.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onSubjectChange = this.onSubjectChange.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  showMessages(messages) {
    if (!this.toast) {
      return;
    }

    messages = (messages ? messages : []);
    if (!(this.state.success || this.state.failure) && !this.state.emailValid) {
      messages.push({severity: "error", summary: "Email Error", detail: "Invalid Email"});
    }
    if (!(this.state.success || this.state.failure) && !this.state.subjectValid) {
      messages.push({severity: "error", summary: "Subject Error", detail: "Empty Subject"});
    }
    if (!(this.state.success || this.state.failure) && !this.state.messageValid) {
      messages.push({severity: "error", summary: "Message Error", detail: "Empty Message"});
    }

    if (this.state.success) {
      messages.push({severity: "success", summary: "Message Sent", detail: "I will get back to you as soon as possible"});
    }

    if (this.state.failure) {
      messages.push({severity: "error", summary: "Message Failed", detail: "Please try again or email me directly at david.lam@lamdav.com"});
    }

    if (messages.length > 0) {
      this.toast.show(messages);
      this.setState({success: false, failure: false});
    }
  }

  onEmailChange(event) {
    this.setState({email: event.target.value, emailValid: isEmail(event.target.value)});
  }

  onSubjectChange(event) {
    this.setState({subject: event.target.value, subjectValid: event.target.value.length > 0});
  }

  onMessageChange(event) {
    this.setState({message: event.target.value, messageValid: event.target.value.length > 0});
  }

  onSubmit(event, data) {
    if (this.state.emailValid && this.state.subjectValid && this.state.messageValid && !this.state.loading) {
      this.setState({loading: true});
      let messages = [];
      this.service.sendEmail(this.state.email, this.state.subject, this.state.message)
        .then((data) => {
          // On success, restore default state but show success message.
          this.setState({
            success: true,
            failure: false,
            loading: false,
            email: "",
            emailValid: false,
            subject: "",
            subjectValid: false,
            message: "",
            messageValid: false
          });
        })
        .catch((error) => {
          if (error.response) {
            const {data} = error.response;
            if (data) {
              messages = data.errors.map((dataError) => dataError.msg)
                .filter((errorMessages) => errorMessages !== undefined);
            } else {
              messages = [{severity: "error", summary: "Network Error", detail: "Server issues."}];
            }
          } else if (error.request) {
            messages = [{severity: "error", summary: "Network Error", detail: "Issue sending request."}];
          } else {
            messages = [{severity: "error", summary: "Network Error", detail: "Issue setting up request."}];
          }

          this.setState({loading: false});
        })
        .finally(() => this.showMessages(messages));
    } else {
      this.showMessages();
    }
  }

  render() {
    const fieldStyle = {
      padding: "0.5em",
      width: "25%"
    };
    return (
      <div>
        <Toast ref={(el) => this.toast = el}/>
        <div className="grid">
          <div className="col-12">
            <label>Email:</label>
            <br/>
            <InputText
              disabled={this.state.loading}
              onChange={this.onEmailChange}
              placeholder="hello@example.com"
              style={fieldStyle}
              type="text"
              value={this.state.email}
            />
          </div>

          <div className="col-12">
            <label>Subject:</label>
            <br/>
            <InputText
              disabled={this.state.loading}
              onChange={this.onSubjectChange}
              placeholder="Hello David!"
              style={fieldStyle}
              type="text"
              value={this.state.subject}
            />
          </div>

          <div className="col-12">
            <label>Message:</label>
            <br/>
            <InputTextarea
              disabled={this.state.loading}
              onChange={this.onMessageChange}
              placeholder="Type your message here..."
              rows={12}
              style={fieldStyle}
              value={this.state.message}
            />
          </div>

          <div className="col-12">
            <button
              className={
                (this.state.loading)
                  ? "button button-info disabled"
                  : "button button-info"
              }
              disabled={this.state.loading}
              onClick={this.onSubmit}
              style={fieldStyle}
              type="submit"
            >
              Submit &nbsp;
              <FontAwesomeIcon icon={faPaperPlane}/>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default MailForm;
