import React, {Component} from "react";

import {Card} from "primereact/card";

class DetailPanel extends Component {
  /*
    Initialize state.
  */
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: "/pic/profile.webp",
      imageSrcSet: "/pic/profile.webp 845w,"
        + "/pic/profile.large.webp 423w,"
        + "/pic/profile.medium.webp 212w,"
        + "/pic/profile.small.webp 106w"
    };
    this.handleImageError = this.handleImageError.bind(this);
  }

  /*
    Handles when webp image cannot be displayed properly.
    Switches to JPG default.
  */
  handleImageError(event) {
    console.error("[ ERROR ]: Browser cannot detect webp format, switching to JPG.");
    this.setState({
      imgSrc: "/pic/profile.jpg",
      imageSrcSet: "/pic/profile.jpg 845w,"
        + "/pic/profile.large.jpg 423w,"
        + "/pic/profile.medium.jpg 212w,"
        + "/pic/profile/small.jpg 106w"
    });
  }

  /*
    Render the component.
  */
  render() {
    return (
      <React.Fragment>
        <div className="col-4">
          <img
            alt="profile"
            onError={this.handleImageError}
            src={this.state.imageSrc}
            srcSet={this.state.imageSrcSet}
            sizes="23vw"
            style={{width: "100%"}}
          />
        </div>

        <Card className="col-8" title="About Me">
          <h5>Background</h5>
          <p>
            I am David Lam and I am a Computer Science and Mathematics alumni of &nbsp;
            <a
              href="http://www.rose-hulman.edu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Rose-Hulman Institute of Technology
            </a>.
            &nbsp; I am currently working at Uber as a Backend Engineer on the Rider Intelligence Team.
            I operate on a team that powers the Uber apps product selection screen by recommending Uber
            products that best suit the rider preference.
          </p>

          <p>
            I previously worked at UnifyID, a start up working on passive gait based authentication. I operated on a team
            that ingested all sensor data, performs data validation and chunking, transforms data
            to a standardized format, and forwards the data to our inference engine. I implemented
            and guided interns in the developement of a framework to determine the effectiveness
            of two different deep-learning models in expected everyday tasks. I help maintain
            tooling to support our machine learning engineers in their daily experimentation as well
            as tooling to support our training pipeline. Most recently, I am maintaining most of
            our Kubernetes Infrastructure on AWS to perform rigorous load testing.             all sensor data, performs data validation and chunking, transforms data
            to a standardized format, and forwards the data to our inference engine. I implemented
            and guided interns in the developement of a framework to determine the effectiveness
            of two different deep-learning models in expected everyday tasks. I help maintain
            tooling to support our machine learning engineers in their daily experimentation as well
            as tooling to support our training pipeline. Most recently, I am maintaining most of
            our Kubernetes Infrastructure on AWS to perform rigorous load testing.
          </p>

          <h5>Interests</h5>
          <p>
            My life revolves around the computer. From work to leisure time,
            it is mostly spent on the computer exploring its infinite possibilities.
            I enjoy learning much about frameworks and libraries such as React and Angular.
            In fact, this website is written entirely in React while I was experimenting with some packages.
            My current topic of interests revolve around scalability and artificial intelligence.
          </p>

          <h5>Beyond Digital</h5>
          <p>
            If you are reading this far, the obvious next question to ask is what else do
            I do besides tinkering. Simply put, there are two other major concentrations
            that dominate my time: Anime and Video Games. I am a huge fan of Japanese Animation.
            To prove this, have a look at &nbsp;
            <a
              href="http://myanimelist.net/profile/GaleWind"
              target="_blank"
              rel="noopener noreferrer"
            >
              MyAnimeList
            </a>.
            &nbsp; I love the portrayal of characters, the depth of the world, and the, sometimes farfetched,
            integration with mythology that anime and, by extension, light novels and mangas have.
            On the other hand, I have grown up with video games. I would go on to say that video games
            have influence how I learn and approach my problems. My favorite genre is Japanese
             Role-Playing Games. It is the perfect blend of my two major interest.
          </p>

          <h5>What am I like?</h5>
          <p>
            By now, I think you get the picture of what I am like. I am some nerd who enjoys nerdy things.
            While I embrace this description, perhaps you might want to know what I am like within a
            working environment. Although my resume and projects might give you some insight, here is
            a succinct overview of what I typically do. In most projects, I enjoy working as a full stack
            developer with minor preference towards backend development. That is, I like working within every
            level of the stack and touching every piece of technology involved. Ultimately, I strive to understand
            how everything fits together. Due to this, I am often found as a leading figure within my small team
            projects as I have become to others a project information clearing house. Fortunately, I have learned
            how to manage and budget development time for sprints, work with uncooperative members, and communicate
            clear goals and expectations.
          </p>
        </Card>
      </React.Fragment>
    );
  }
}

export default DetailPanel;
